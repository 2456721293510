import { useRouter } from "next/router"
import React from "react"

const SCRIPT_ID = "storyblok-bridge"

const StoryblokBridge = ({ children }) => {
  const router = useRouter()
  const inStoryblokContext =
    (router && router.query && router.query._storyblok) || true

  React.useEffect(() => {
    if (!inStoryblokContext || document.getElementById(SCRIPT_ID)) {
      return
    }
    const script = document.createElement("script")
    script.id = SCRIPT_ID
    script.src =
      "//app.storyblok.com/f/storyblok-latest.js?t=2zuM5FT7WibQvAKBN0Adxgtt"
    script.async = true

    const onScriptLoad = () => {
      if (window.storyblok) {
        window.storyblok.init({})

        window.storyblok.on(["published", "change"], () =>
          location.reload(true)
        )

        storyblok.pingEditor(function() {
          if (storyblok.inEditor) {
            storyblok.enterEditmode()
          }
        })
      }
    }

    const onScriptError = () => {
      script.remove()
    }

    script.addEventListener("load", onScriptLoad)
    script.addEventListener("error", onScriptError)

    // Add script to document body
    document.body.appendChild(script)

    // Remove event listeners on cleanup
    return () => {
      script.removeEventListener("load", onScriptLoad)
      script.removeEventListener("error", onScriptError)
    }
  }, [inStoryblokContext])

  return children
}

export default StoryblokBridge
