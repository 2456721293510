import "../styles/index.css"
import "@reach/dialog/styles.css"

import * as Sentry from "@sentry/node"
import App from "next/app"
import Router from "next/router"
import React from "react"
import { openPopupWidget } from "react-calendly"
import uaParser from "ua-parser-js"

import { AppContextProvider } from "../components/app-context"
import { CartContextProvider } from "../components/cart-context"
import { NotificationContextProvider } from "../components/notification-context"
import StoryblokBridge from "../components/storyblok-bridge"
import { getAcceptCookie } from "../lib/cookie"
import * as analytics from "../lib/google-analytics"

Sentry.init({
  dsn: "https://f1521a5209d240d1ad8d0caddb49afa8@sentry.io/5190614",
  environment: process.env.NODE_ENV || "development",
})

Router.events.on("routeChangeComplete", url => analytics.pageview(url))

export default class MyApp extends App {
  static async getInitialProps(props) {
    const initialProps = await App.getInitialProps(props)
    if (props.ctx.req) {
      const host = props.ctx.req.headers.host
      const ua = uaParser(props.ctx.req.headers["user-agent"])
      const isStoryblokEditor = props.ctx.req.url.indexOf("_storyblok") > -1
      const acceptsCookies =
        isStoryblokEditor || getAcceptCookie(props.ctx.req.headers.cookie)
      const isMobileDevice = ua.device.type === "mobile"
      const appProps = {
        isMobileDevice,
        host,
        acceptsCookies,
      }

      return { appProps, ...initialProps }
    }

    return {
      appProps: window.appProps,
      ...initialProps,
    }
  }

  componentDidMount() {
    // make sure appProps are accessable on the client
    window.appProps = this.props.appProps
    // allow hover effects on touch devices
    document.addEventListener("touchstart", function() {}, true)
    // quick hack because this needs to go out asap
    document.addEventListener(
      "click",
      function(event) {
        const { target } = event
        if (
          target.tagName === "A" &&
          target.href &&
          target.href.indexOf("calendly.com") > -1
        ) {
          event.preventDefault()
          openPopupWidget({ url: target.href })
        }
      },
      true
    )
  }

  render() {
    const { Component, appProps, pageProps } = this.props

    // Workaround for https://github.com/zeit/next.js/issues/8592
    const { err } = this.props
    const modifiedPageProps = { ...pageProps, err }

    return (
      <AppContextProvider {...appProps}>
        <StoryblokBridge>
          <NotificationContextProvider>
            <CartContextProvider>
              <Component {...modifiedPageProps} />
            </CartContextProvider>
          </NotificationContextProvider>
        </StoryblokBridge>
      </AppContextProvider>
    )
  }
}
