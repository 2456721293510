import cookie from "cookie"

export const COOKIE_NAME = "accept-cookie"
export const COOKIE_VERSION = "2"

export const getAcceptCookie = (cookieString = "") => {
  const cookies = cookie.parse(cookieString)
  const acceptCookie = cookies[COOKIE_NAME]

  if (acceptCookie && parseInt(acceptCookie, 10) >= COOKIE_VERSION) {
    return true
  }

  return false
}

export const createAcceptCookie = () => {
  return cookie.serialize(COOKIE_NAME, COOKIE_VERSION, {
    sameSite: "lax",
    secure: process.env.NODE_ENV === "production",
    maxAge: 72576000,
    httpOnly: true,
    path: "/",
  })
}
